export const collectTraderFeeButtonStyle = {
    size: "sm",
    color: "gray.100",
    borderRadius: "3",
    bg: "pElementTransparent.810",
    boxShadow: "0 0 3px 0px #ccc",
    _hover: {
        color: "gray.100",
        textDecoration: "none",
        bg: "pElementTransparent.820",
        boxShadow: "0 0 5px 0px #6B5EFF",
    },
};
