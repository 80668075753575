export const tableStyle = {
    _hover: {
        textDecoration: "none",
        bg: "pElementTransparent.890",
    },
};

export const tableTrStyle = {
    borderRadius: "8px",
    border: "none",

    width: "100%",
    height: "100%",


    transition: "box-shadow 3s",

    ":hover": {
        textDecoration: "none",
        transition: "box-shadow 0.7s",
        boxShadow: "inset 1000px 0px 100px rgba(107, 94, 255, 0.1)",
    },

};