
export const defaultLinkButtonStyle = {
    size: "md",
    borderRadius: "md",
    bg: "none",
    mr: "0",
    _hover: {
        textDecoration: "none",
        bg: "pElementTransparent.880",
    },
};

