export const FIDENARO_COMPONENT_ADDRESS = "component_tdx_2_1cq2edjd2y6d8h0zzsjhvhvnl8m6urt92f69rqa5vsyujvdqy2du53s";
export const TRADE_VAULT_STORE = "internal_keyvaluestore_tdx_2_1kpp9fx70wmnmnsr4g3g08w9qsw0p9nn9lswgd4trs0ztkdarsalxsa";
// Test config
// export const FIDENARO_COMPONENT_ADDRESS = "component_tdx_2_1crtmu9ds2j09ec0u93z0mzhzk7fnkvu30rsqu6tr4lj5an3cxc96mw";
// export const TRADE_VAULT_STORE = "internal_keyvaluestore_tdx_2_1kpp9fx70wmnmnsr4g3g08w9qsw0p9nn9lswgd4trs0ztkdarsalxsa";
export const TRADE_VAULT_PACKAGE_ADDRESS = "package_tdx_2_1pklvdrayy7ndz736emnaal3h3msmlrvwvtx5cs05p6jzcqneyzyrjk";
export const USER_NFT_RESOURCE_ADDRESS = "resource_tdx_2_1ngtlf76m2zyjqegwgscpn7skkzfxe7pu66hnne4lmur7fd7xlvn0ty"
export const TRADE_ENGINE_COMPONENT_ADDRESS = "component_tdx_2_1crn4j26qyjhg5qtu9368h6daxnux27fhuaj9n4r4krw89eawhdru63";
export const PRICE_STORE = "internal_keyvaluestore_tdx_2_1kz8e9pw83zlpzut3mtr5k6qpmuf3u4ksjunhu9z007vesvfsytarjy";
export const USER_FACTORY_COMPONENT_ADDRESS = "component_tdx_2_1cqwugs2zf5lgjt5n6y85u2menzucrpsq78tpxy7ahdundre52ntll9"
export const FIDENARO_FEE = 0.01


export const BLACKLIST = [
    "component_tdx_2_1cru00swt2hnz285jrw64cd6c5zfae28a6540lcl85x3vx9hsyz06ea", "component_tdx_2_1cps7phmvecv0zukx9y9zkjduu4f5pj98959np22r4ngn6nf8h55axr", "component_tdx_2_1cze7ddwkct7ve375qtuvezghlea8rgtc3wha62dgxw99x904qr7j9v", "component_tdx_2_1crr4jzhzfhlpups3d6pels2mppfy6dq9qchv2kn98vwphcc5suhsgy", "component_tdx_2_1czj8cxzdk4nlr2u02l265hqvv9zm8fucueaw2g2te4pt6hfvrxaa3l", "component_tdx_2_1cr2swj9dmpjpwdnnu704lvnrszfyxf5zx3eza29k3zakp7d9n9hqrr",
    "component_tdx_2_1czvpcqqkfj4ld22esltwjyug2rlez8m7yq9me40wyftl7yxjrev33g",
    "component_tdx_2_1czvpcqqkfj4ld22esltwjyug2rlez8m7yq9me40wyftl7yxjrev33g",
    "component_tdx_2_1cpk2qu6ake0j5f78e8u69q62d4v7y8as30myps8up3l75u07tv3w3f",
    "component_tdx_2_1cr3xe066yvlhhsn9jx7aw8z04n8rm7k459f3z42nssapz4x429n3j8",
    "component_tdx_2_1czzf7gplqclewmff65gqytem3we0pd5ma942686z8jqhkrjl3vj2ns",
    "component_tdx_2_1cpn2lxhcng8s7y5cfqk2g3fcpq5z6ncxav0nqqfcrn2emmu7f6nl57",
    "component_tdx_2_1cqfjkhlczpm8qhw9daap7f97dwskg06zdkwe3jxu2clmdglm7as0p6",
    "component_tdx_2_1cpc0ln6e25emtt2mtv7n70msx9mgpkef06l06g4x9g8560zhlgmf2w",
    "component_tdx_2_1czjv05qds4vkyk03m8kymvzuqf7jwd8qq6qa872m7p5s97vmdphr7w",
    "component_tdx_2_1crl3h42whfjxyh4m0aay5y0s9dy7q4mvgc6mnh38ll8t5htwcdjnmd",
    "component_tdx_2_1cpwwja583utmpea3yarm83grt4feyyesqhkzemzpp70467g7u57mct",
    "component_tdx_2_1cqlp9cj78ee8ushcglekz67mvwl97pyxnkhqn7v0mtc0q0nw630xuh",
    "component_tdx_2_1cru3xe9r045u3t9exf956fzl568cpesjlug0g7d3ytj9h945n06jr8",
    "component_tdx_2_1cz38fng0tah2yatty4n86we9dj9sptzal8pxsyv5y8waeak9xlwvhz",
    "component_tdx_2_1cq54nyktkse468lkn7lj2ltuse40ggeq89ap4h7kqka0cymnyjtawp",
]